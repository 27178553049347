<template>
  <div>
    <el-dialog :title="`Add New Offer`" :visible.sync="localVisible">
      <el-form :rules="rules" :model="dialogFormData" ref="offersCreateFromRef">
        <el-form-item
          :label="`Wholesaler`"
          prop="entity_id"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.entity_id"
            clearable
            filterable
            remote
            :remote-method="filterData"
            :loading="loading"
            style="width: 100%"
            @change="dropdownChanged('entity_id', ['product_id', 'unit_id'])"
            @visible-change="resetRemoteSelect()"
          >
            <el-option
              v-for="p in filteredWhsData"
              :key="p.key"
              :label="p.label"
              :value="p.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="`Product Name`"
          prop="product_id"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.product_id"
            clearable
            filterable
            remote
            :remote-method="searchProducts"
            :loading="loading"
            style="width: 100%"
            @change="productChosen"
            @clear="clearProductClicked"
            :disabled="!dialogFormData.entity_id"
          >
            <el-option
              v-for="p in fetchedProducts"
              :key="p.id"
              :label="p.name"
              :value="p.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`Unit Name`" prop="unit_id" label-width="160px">
          <el-select
            v-model="dialogFormData.unit_id"
            clearable
            filterable
            remote
            style="width: 100%"
            :disabled="!dialogFormData.product_id"
            @change="dropdownChanged('unit_id', [])"
          >
            <el-option
              v-for="p in fetchedProductUnits"
              :key="p.unitId"
              :label="p.unit"
              :value="p.unitId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div style="display: flex">
          <el-form-item
            :label="`Original Price`"
            prop="original_price"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.original_price"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`Offer Price`"
            prop="price"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.price"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>

        <div
          v-if="discrepancyAmount"
          class="offers-dialog__discrepancy-message"
        >
          NB: The Discrepancy between the Original Price and Offer Price is
          {{ discrepancyAmount }}%
        </div>

        <div style="display: flex">
          <el-form-item
            :label="`Min`"
            prop="min_per_order"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.min_per_order"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`Max`"
            prop="max_per_order"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.max_per_order"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item :label="`Offer Type`" prop="type" label-width="160px">
          <el-select
            v-model="dialogFormData.type"
            clearable
            filterable
            style="width: 100%"
            @change="dropdownChanged('type', [])"
          >
            <el-option
              v-for="p in offerTypes"
              :key="p.key"
              :label="p.key"
              :value="p.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`Offer Promoter`"
          prop="promoter"
          label-width="160px"
        >
          <el-select
            v-model="dialogFormData.promoter"
            clearable
            filterable
            style="width: 100%"
            @change="promoterTypeChanged"
          >
            <el-option
              v-for="p in offerPromoterTypes"
              :key="p.key"
              :label="p.key"
              :value="p.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div style="display: flex">
          <el-form-item
            :label="`Fatura`"
            prop="fatura_amount"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.fatura_amount"
              autocomplete="off"
              :disabled="
                dialogFormData.promoter && dialogFormData.promoter == 'whs'
              "
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`WHS`"
            prop="entity_amount"
            style="flex-basis: 50%"
          >
            <el-input
              type="number"
              v-model="dialogFormData.entity_amount"
              autocomplete="off"
              :disabled="
                dialogFormData.promoter && dialogFormData.promoter == 'fatura'
              "
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item
          :label="`Display Order`"
          prop="display_order"
          label-width="160px"
        >
          <el-input
            type="number"
            v-model="dialogFormData.display_order"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <div style="display: flex">
          <el-form-item
            :label="`Start Date`"
            prop="start_date"
            label-width="160px"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.start_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label-width="160px"
            :label="`End Date`"
            prop="end_date"
            style="flex-basis: 50%"
          >
            <el-date-picker
              v-model="dialogFormData.end_date"
              type="datetime"
              placeholder="Select date and time"
              value-format="timestamp"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item
          :label="`Communication Method`"
          prop="communication_method"
          label-width="200px"
        >
          <el-select
            v-model="dialogFormData.communication_method"
            @change="communicationMethodChange"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="p in communicationTypes"
              :key="p"
              :label="p"
              :value="p"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`Communication Title`"
          prop="communication_title"
          label-width="200px"
        >
          <!-- <el-input
            v-model="dialogFormData.communication_title"
            autocomplete="off"
          ></el-input> -->
          <Picker
            :native="true"
            :showCategories="true"
            :showPreview="true"
            :style="{
              position: 'absolute',
              left: ' 20%',
              'z-index': '99999',
              display: pickerShown ? 'flex' : 'none',
            }"
            @select="addEmoji($event, 'markdownEditorTitle')"
          />
          <Editor
            @load="onEditorLoad($event, 'markdownEditorTitle')"
            ref="markdownEditorTitle"
            height="250px"
            initialEditType="wysiwyg"
            :options="editorOptions"
          />
        </el-form-item>

        <el-form-item
          :label="`Communication Description`"
          prop="communication_description"
          label-width="200px"
        >
          <!-- <el-input
            v-model="dialogFormData.communication_description"
            autocomplete="off"
          ></el-input> -->
          <Picker
            :native="true"
            :showCategories="true"
            :showPreview="true"
            :style="{
              position: 'absolute',
              left: ' 20%',
              'z-index': '99999',
              display: picker2Shown ? 'flex' : 'none',
            }"
            @select="addEmoji($event, 'markdownEditorDescription')"
          />
          <Editor
            @load="onEditorLoad($event, 'markdownEditorDescription')"
            ref="markdownEditorDescription"
            height="250px"
            initialEditType="wysiwyg"
            :options="editorOptions"
          />
        </el-form-item>
        <!-- <el-form-item
          :label="`Notification Deeplink`"
          prop="notification_deeplink"
          label-width="200px"
        >
          <el-input
            v-model="dialogFormData.notification_deeplink"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- new end -->
        <el-form-item
          :label="`Communication Date`"
          prop="communication_date"
          label-width="200px"
          v-if="dialogFormData.communication_method"
        >
          <el-date-picker
            v-model="dialogFormData.communication_date"
            type="datetime"
            placeholder="Select date and time"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="createOffer">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { offersService } from "@/services/offers.service.js";
import { utils } from "@/utils";
import { mapGetters } from "vuex";
import { providersService } from "@/services/providers.service.js";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import { Picker } from "emoji-mart-vue";
import customValidators from "../../../utils/customValidators";
function createLastButton(handler) {
  const button = document.createElement("button");
  button.className = "toastui-editor-toolbar-icons last";
  button.style.backgroundImage = "none";
  button.style.margin = "0";
  button.innerHTML = `<i class="el-icon-bicycle"></i>`;
  button.addEventListener("click", (e) => {
    handler();
    e.preventDefault();
  });
  return button;
}

export default {
  name: "OffersDialog",
  components: {
    Editor,
    Picker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let priceValidator = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("Price should be bigger than 0"));
      } else {
        if (this.dialogFormData.original_price) {
          const originalPriceInt = parseInt(this.dialogFormData.original_price);
          const priceInt = parseInt(value);
          if (originalPriceInt < priceInt) {
            callback(new Error("Price should be less than original price"));
            return;
          }
          return callback();
        } else {
          callback();
        }
      }
    };
    let originalPriceValidator = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("Price should be bigger than 0"));
      } else {
        callback();
      }
    };
    return {
      localVisible: false,
      dataArray: [
        {
          key: 1,
          name: null,
        },
      ],
      rules: {
        entity_id: [
          {
            required: true,
            message: "Please select a wholesaler",
            trigger: "blur",
          },
        ],
        product_id: [
          {
            required: true,
            message: "Please select a product",
            trigger: "blur",
          },
        ],
        unit_id: [
          {
            required: true,
            message: "Please select a valid unit",
            trigger: "blur",
          },
        ],
        original_price: [
          {
            required: true,
            message: "Please input original price",
            trigger: "blur",
          },
          {
            validator: originalPriceValidator,
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Please input offer price",
            trigger: "blur",
          },
          {
            validator: priceValidator,
            trigger: "blur",
          },
        ],
        max_per_order: [
          {
            required: true,
            message: "Please input max items per order",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select offer type",
            trigger: "blur",
          },
        ],
        promoter: [
          {
            required: true,
            message: "Please select promoter type",
            trigger: "blur",
          },
        ],
        fatura_amount: [
          {
            required: true,
            message: "Please input fatura amount",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: customValidators.onlyPositiveNumbervalidator,
          },
        ],
        entity_amount: [
          {
            required: true,
            message: "Please input wholesaler amount",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: customValidators.onlyPositiveNumbervalidator,
          },
        ],
        start_date: [
          {
            required: true,
            message: "Please input start date",
            trigger: "blur",
          },
        ],
        end_date: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
        ],
        display_order: [
          {
            validator: customValidators.onlyPositiveNumbervalidator,
            message: "Please input positive values only",
            trigger: "blur",
          },
        ],
        communication_date: [
          {
            required: true,
            message: "Please input communication date",
            trigger: "blur",
          },
        ],
      },
      defaultCreateData: {
        entity_id: null,
        product_id: null,
        unit_id: null,
        original_price: null,
        price: null,
        type: null,
        entity_amount: null,
        fatura_amount: null,
        start_date: null,
        end_date: null,
        max_per_order: null,
        min_per_order: null,
        promoter: null,
        communication_description: null,
        communication_title: null,
        communication_method: null,
        // notification_deeplink: null,
        communication_date: null,
      },
      dialogFormData: null,
      filteredWhsData: [],
      loading: false,
      fetchedProducts: [],
      fetchedProductUnits: [],
      offerTypes: [
        {
          key: "Hot Offer",
          value: "hot_offer",
        },
        {
          key: "Flash Offer",
          value: "flash_offer",
        },
      ],
      offerPromoterTypes: [
        {
          key: "Fatura",
          value: "fatura",
        },
        {
          key: "WHS",
          value: "whs",
        },
        {
          key: "Mix",
          value: "mix",
        },
      ],
      communicationTypes: ["SMS", "Notification", "Both"],
      pickerShown: false,
      picker2Shown: false,
      editorOptions: {
        minHeight: "200px",
        useDefaultHTMLSanitizer: true,
        useCommandShortcut: false,
        hideModeSwitch: true,
        toolbarItems: [["bold", "italic", "strike", "heading"]],
        autofocus: false,
      },
      editorText: "This is initialValue.",
    };
  },
  watch: {
    localVisible(value, oldValue) {
      if (value === false) {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        this.$refs["offersCreateFromRef"].resetFields();
      }
      if (value !== oldValue) {
        this.$emit("input", value);
      }
    },
    value(newVal) {
      this.localVisible = newVal;
    },
  },
  created() {
    this.dialogFormData = { ...this.defaultCreateData };
    this.localVisible = this.value;
  },
  methods: {
    async createOffer() {
      this.$refs["offersCreateFromRef"].validate(async (isValid) => {
        try {
          if (this.dialogFormData.communication_method) {
            let titleMarkdown =
              this.$refs.markdownEditorTitle.invoke("getMarkdown");
            let descMarkdown =
              this.$refs.markdownEditorDescription.invoke("getMarkdown");
            if (titleMarkdown.length)
              this.dialogFormData.communication_title = titleMarkdown;
            else {
              utils.popupMessageWrapper(
                "Communication Title is required",
                "error",
                3500
              );
              return;
            }
            if (descMarkdown.length)
              this.dialogFormData.communication_description = descMarkdown;
            else {
              utils.popupMessageWrapper(
                "Communication Description is required",
                "error",
                3500
              );
              return;
            }
          }
          if (isValid) {
            let apiObject = JSON.parse(JSON.stringify(this.dialogFormData));
            if (
              apiObject.hasOwnProperty("min_per_order") &&
              apiObject.min_per_order === ""
            ) {
              apiObject.min_per_order = null;
            }
            if (!apiObject.communication_method) {
              delete apiObject.communication_title;
              delete apiObject.communication_description;
              delete apiObject.communication_method;
              delete apiObject.communication_date;
            }
            if (
              apiObject.hasOwnProperty("communication_title") &&
              !apiObject.communication_title
            ) {
              delete apiObject.communication_title;
              delete apiObject.communication_description;
              delete apiObject.communication_method;
              delete apiObject.communication_date;
            }
            if (
              apiObject.hasOwnProperty("display_order") &&
              apiObject.display_order === ""
            ) {
              delete apiObject.display_order;
            }
            const response = await offersService.addOffer(apiObject);
            if (response && response.status == 200) {
              utils.popupMessageWrapper(
                "Offer created successfully",
                "success",
                1500
              );
              setTimeout(() => {
                this.$refs.markdownEditorTitle.invoke("setMarkdown", "");
                this.$refs.markdownEditorDescription.invoke("setMarkdown", "");

                this.$emit("offerCreated");
              }, 1300);
            }
          }
        } catch (error) {
          console.log("createOfferError", error);
          const errorMessage = utils.errorMessageExtractor(error);
          utils.popupMessageWrapper(errorMessage, "error", 3500);
        }
      });
    },
    filterData(text) {
      try {
        this.loading = true;
        let finalArray = this.wholesalersLookup.filter((singleItem) => {
          if (text.length > 0) {
            return singleItem.label.toLowerCase().match(text.toLowerCase());
          }
          return false;
        });
        this.filteredWhsData = finalArray;
        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.loading = false;
      }
    },
    async searchProducts(text) {
      this.loading = true;
      if (text !== "") {
        const filtersObject = {
          name: text.trim(),
          // isInStock: 0,
        };
        const providerId = this.dialogFormData.entity_id;
        try {
          this.loading = true;
          const res = await providersService.getProviderProducts(
            providerId,
            filtersObject
          );
          if (res) {
            this.fetchedProducts = res.data.data;
            this.loading = false;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
      this.loading = false;
    },
    productChosen(productId) {
      this.dropdownChanged("product_id", ["unit_id"]);
      if (productId) {
        this.fetchedProductUnits = this.fetchedProducts.filter(
          (item) => item.id == productId
        )[0].productUnits;
      }
    },
    clearProductClicked() {
      this.dataEraser(["unit_id"]);
    },
    dataEraser(keysArray) {
      keysArray.forEach((singleKey) => {
        this.dialogFormData[singleKey] = null;
        if (singleKey == "product_id") {
          this.fetchedProducts = [];
        }
        if (singleKey == "unit_id") {
          this.fetchedProductUnits = [];
        }
      });
    },
    promoterTypeChanged(newType) {
      this.dropdownChanged("promoter", []);
      if (newType == "fatura") {
        this.dialogFormData.entity_amount = 0;
      }
      if (newType == "whs") {
        this.dialogFormData.fatura_amount = 0;
      }
    },
    onEditorLoad(editor, target) {
      // To fix a bug in the editor load event called when editor  not fully loaded
      setTimeout(() => {
        editor.insertToolbarItem(
          { groupIndex: 0, itemIndex: 4 },
          {
            el: createLastButton(() => {
              if (target == "markdownEditorTitle") {
                this.pickerShown = !this.pickerShown;
                return;
              }
              this.picker2Shown = !this.picker2Shown;
            }),
            tooltip: "Emoji",
            style: "color:black;",
          }
        );
      }, 0);
    },
    addEmoji(emoji, target) {
      this.$refs[target].invoke("insertText", emoji.native);
      if (target == "markdownEditorTitle") {
        this.pickerShown = false;
        return;
      }
      this.picker2Shown = false;
    },
    dropdownChanged(key, clearArray) {
      this.$refs.offersCreateFromRef.validateField(key);
      this.dataEraser(clearArray);
    },
    resetRemoteSelect() {
      this.filteredWhsData = this.filteredWhsData.filter(
        (d) => d.key == this.dialogFormData.entity_id
      );
    },
    communicationMethodChange(communicationMethod) {
      if (!communicationMethod) {
        this.dialogFormData.communication_date = undefined;
      }
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    wholesalersLookup() {
      let targetLookup =
        this.lookups && this.lookups["distributers"]
          ? this.lookups["distributers"]
          : [];
      let returnedArray = targetLookup.map((item) => {
        if (item.id && item.name) {
          return {
            key: item.id,
            label: item.name,
          };
        }
        return {
          key: "",
          label: "",
        };
      });
      return returnedArray;
    },
    discrepancyAmount() {
      let calculatedValue = null;
      try {
        if (!this.dialogFormData.original_price || !this.dialogFormData.price)
          return null;
        calculatedValue =
          ((this.dialogFormData.original_price - this.dialogFormData.price) /
            this.dialogFormData.original_price) *
          100;
        if (!(calculatedValue > 5)) calculatedValue = null;
        else calculatedValue = Math.ceil(calculatedValue);
      } catch (error) {
        console.log("error :>> ", error);
        calculatedValue = null;
      }
      return calculatedValue;
    },
  },
};
</script>

<style lang="scss">
.offers-dialog__discrepancy-message {
  text-align: center;
  color: red;
  margin-bottom: 15px;
}
</style>
