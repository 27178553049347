<template>
  <div>
    <div
      class="buttons-container u-display-flex u-justify-content--between u-align-items--center u-bottom-margin--2x"
    >
      <el-upload
        class="u-right-margin--2x u-left-margin--2x"
        :on-change="uploadSheetFileChanged"
        :on-remove="removeSheetFile"
        action="#"
        :multiple="false"
        :auto-upload="false"
        :file-list="sheetFileList"
        accept=".xlsx, .csv"
      >
        <el-button slot="trigger" size="mini" type="primary">
          {{ $t("GlobalSelectFileButtonLabel") }}
        </el-button>
        <el-dropdown size="medium" @command="handleSheetImport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-top"
            type="info"
            plain
            :disabled="sheetFileList.length == 0"
            :title="sheetFileList.length == 0 ? 'true' : 'false'"
          >
            {{ $t("GlobalImportExcelButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown" v-if="sheetFileList.length != 0">
            <el-dropdown-item command="create">Add</el-dropdown-item>
            <el-dropdown-item command="update">Update</el-dropdown-item>
            <el-dropdown-item command="assign">Assign Users</el-dropdown-item>
            <el-dropdown-item command="unassign"
              >Unassign Users</el-dropdown-item
            >
            <!-- <el-dropdown-item command="delete">Delete</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </el-upload>

      <div class="exportWrapper">
        <!-- Template Download  -->
        <div style="display: flex; margin-right: 12px">
          <el-dropdown>
            <el-button type="info" class="mr-1" size="small">
              Template Download Options<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="customDropDown">
              <el-dropdown-item command="true">
                <el-button
                  style="width: 100%"
                  size="mini"
                  icon="el-icon-bottom"
                  type="info"
                  plain
                  @click="handleSheetExport('create')"
                >
                  Offers Template
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="true">
                <el-button
                  style="width: 100%"
                  size="mini"
                  icon="el-icon-bottom"
                  type="info"
                  plain
                  @click="handleExportUsersOffers('create')"
                >
                  User Assignation Template
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- Template Download  -->

        <div style="display: flex">
          <el-dropdown>
            <el-button type="primary" class="mr-1" size="small">
              Offers Export Options<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="customDropDown">
              <el-dropdown-item command="true">
                <el-button
                  style="width: 100%"
                  type="primary"
                  icon="el-icon-download"
                  size="mini"
                  @click="handleSheetExport('update')"
                >
                  <!-- {{ $t("GlobalExportFileButtonLabel") }} -->
                  Export Offers
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item command="true">
                <el-button
                  style="width: 100%"
                  type="primary"
                  icon="el-icon-download"
                  size="mini"
                  @click="handleExportUsersOffers('update')"
                >
                  Export Users With Offers
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
  </div>
</template>

<script>
import { offersService } from "@/services/offers.service";

export default {
  name: "OffersSheetsHandler",
  props: {
    chosenFilters: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sheetFileList: [],
    };
  },
  methods: {
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(chosenAction) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      try {
        const response = await offersService.importOffersSheet(
          toBeSentForm,
          chosenAction
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            console.log("parsedResponse", parsedResponse);
            if (parsedResponse.data.message) {
              this.$message({
                message: parsedResponse.data.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "error",
              duration: 4000,
            });
          }
        }
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 3500);
        // this.$message({
        //   message:"Error Occured",
        //   type: "error",
        //   duration: 2000,
        // });
      }
      this.sheetFileList = [];
      this.$emit("dataUpdated");
    },
    async handleSheetExport(chosenAction) {
      const isCreate = chosenAction === "create";

      try {
        const response = await offersService.exportOffersSheet(
          chosenAction,
          this.chosenFilters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = isCreate
            ? "OffersCreateTemplate.xlsx"
            : `OffersUpdateSheet.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    hasOtherProps(obj) {
      const keys = Object.keys(obj);
      if (keys.length > 1) {
        return true;
      }
      return false;
    },
    async handleExportUsersOffers(chosenAction) {
      const isCreate = chosenAction === "create";

      try {
        //** Check if the user not select a filter to export the results  **/
        if (!this.hasOtherProps(this.chosenFilters) && !isCreate) {
          this.$message({
            message: "Please Choose at least one filter to export results",
            type: "error",
            duration: 2000,
          });
          return;
        }

        const response = await offersService.exportUsersOffersSheet(
          chosenAction,
          this.chosenFilters
        );

        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = isCreate
            ? "OffersConsumersCreateSheet.xlsx"
            : `OffersConsumersUpdateSheet.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.exportWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.exportOffers {
  margin-left: 12px;
}
.buttons-container {
  background-color: white;
  padding: 15px 10px;
  border: 0.5px solid #dcdfe6;
  border-radius: 5px;
}
</style>
