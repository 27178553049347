<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- tabs area -->
    <!-- ************************************************************************************ -->
    <el-row type="flex" justify="center">
      <el-col :span="12">
        <el-tabs v-model="activeTabName" @tab-click="tabClicked" stretch>
          <el-tab-pane
            v-for="item in navigationItems"
            :label="item.tabLabel"
            :name="item.tabName"
            :key="item.tabLabel"
          ></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <!-- ************************************************************************************ -->
    <!-- filters area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            :meta="metric.meta ? metric.meta : null"
            @change="filtersChanged(metric.key, $event)"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <OffersSheetsHandler
      :chosenFilters="{ ...filters, tab: activeTabName }"
      @dataUpdated="getOffers(currentPage, filters)"
    />
    <div class="u-display-flex u-justify-content--end u-bottom-margin--2x">
      <el-button type="primary" @click="showOffersDialog = true">
        Add New Offer
      </el-button>
    </div>
    <!-- ************************************************************************************ -->
    <!-- table area -->
    <!-- ************************************************************************************ -->
    <template>
      <el-table :data="offersList" border stripe>
        <el-table-column prop="entity_id" :label="`WHS ID`" />
        <el-table-column prop="entity.name" :label="`WHS Name`" />
        <el-table-column prop="product.name" :label="`Product Name`" />
        <el-table-column prop="unit.name" :label="`Unit Name`" />
        <el-table-column prop="original_price" :label="`Original Price`" />
        <el-table-column prop="price" :label="`Offer Price`" />
        <el-table-column :label="`Max. Items Per Product Range`">
          <el-table-column prop="max_per_order" :label="`Max`" width="75" />
          <el-table-column prop="min_per_order" :label="`Min`" width="75" />
          <el-table-column prop="item_per_order" :label="`Items Per order`" />
        </el-table-column>
        <el-table-column prop="fatura_amount" :label="`Fatura Amount`" />
        <el-table-column prop="entity_amount" :label="`WHS Amount `" />
        <el-table-column prop="start_date" :label="`Start Date`">
          <template slot-scope="prop">
            <!-- <div>{{ prop.row.start_date }}</div>
            <div>text</div> -->
            <div>{{ prop.row.start_date | moment("DD/MM/YYYY HH:mm:ss") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="end_date" :label="`End Date`">
          <template slot-scope="prop">
            <!-- <div>{{ prop.row.end_date }}</div>
            <div>text</div> -->
            <div>{{ prop.row.end_date | moment("DD/MM/YYYY HH:mm:ss") }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="display_order" :label="`Display Order`" />
        <el-table-column prop="type" :label="`Offer Type`" />

        <el-table-column
          prop="operations"
          :label="$t('GlobalOperationsLabel')"
          v-if="activeTabName !== 'deactivated'"
        >
          <template slot-scope="props">
            <div class="flex-container">
              <el-button
                type="success"
                size="mini"
                @click="offerRowClicked(props.row)"
                class="u-bottom-margin--1x"
              >
                {{ $t("ActiveAgentsPageTabelValueBtnEdit") }}
              </el-button>
              <el-button
                v-if="activeTabName == 'upcoming'"
                type="danger"
                size="mini"
                @click="stopOffer(props.row)"
                style="margin: 0"
                class="u-bottom-margin--1x"
              >
                Stop
              </el-button>
              <el-button
                v-if="activeTabName == 'active'"
                type="danger"
                size="mini"
                @click="clickedOfferToDeactivate = props.row"
                style="margin: 0"
                class="u-bottom-margin--1x"
              >
                Deactivate
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.count"
        :current-page="currentPage"
        :page-size="25"
        @current-change="pageChanged"
      ></el-pagination>
    </div>

    <OffersDialog v-model="showOffersDialog" @offerCreated="offerCreated" />
    <OffersEditDialog v-model="clickedOffer" @offerEdited="offerEdited" />
    <OffersDeactivationDialog
      v-model="clickedOfferToDeactivate"
      :possibleReasons="cancellationReasonsConfig"
      @saveClicked="deactivateOffer"
    />
  </div>
</template>

<script>
import { offersService } from "@/services/offers.service";
import { mapGetters } from "vuex";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { offersMetrics } from "@/components/transaction/metricboxComponents";
import OffersDialog from "./OffersDialog.vue";
import _ from "lodash";
import OffersSheetsHandler from ".//OffersSheetsHandler.vue";
import OffersEditDialog from "./OffersEditDialog.vue";
import { adminActions } from "@/services";
import OffersDeactivationDialog from "./OffersDeactivationDialog.vue";

export default {
  name: "OffersPage",
  components: {
    MetricFilterBox,
    metricbox,
    ...offersMetrics,
    OffersSheetsHandler,
    OffersDialog,
    OffersEditDialog,
    OffersDeactivationDialog,
  },
  data() {
    return {
      activeTabName: "upcoming",
      navigationItems: [
        {
          tabLabel: "Upcoming",
          tabName: "upcoming",
        },
        {
          tabLabel: "Active",
          tabName: "active",
        },
        {
          tabLabel: "Deactivated",
          tabName: "deactivated",
        },
      ],
      offersList: [],
      originalData: [],
      pagination: {},
      filters: {},
      metricsList: [],
      metrics: {
        states: [
          {
            key: "offerId",
            label: "Offer ID",
            compName: "stringMetric",
            meta: {
              placeholder: "Offer ID",
            },
          },
          {
            key: "entity_id",
            label: "WHS ID",
            compName: "stringMetric",
            meta: {
              placeholder: this.$t("providerAreaPageAreaFilterPlaceHolder"),
            },
          },
          {
            key: "entity_name",
            label: "WHS Name",
            compName: "localSearchMetric",
            meta: {
              placeholder: "Type name",
              multiple: false,
              lookupKey: "distributers",
            },
          },
          {
            key: "product_id",
            label: "Product Id",
            compName: "stringMetric",
          },
          {
            key: "unit_id",
            label: "Unit Name",
            compName: "localSearchMetric",
            meta: {
              placeholder: "Type name",
              multiple: false,
              lookupKey: "unitsMain",
            },
          },
          {
            key: "type",
            label: "Offer Type",
            compName: "dynamicDropdownMetric",
            meta: {
              data: [
                {
                  key: "hot_offer",
                  label: "Hot Offer",
                },
                {
                  key: "flash_offer",
                  label: "Flash Offer",
                },
              ],
              placeholder: "Select Type",
              multiple: false,
            },
          },
          {
            key: "promoter",
            label: "Promoter Type",
            compName: "dynamicDropdownMetric",
            meta: {
              data: [
                {
                  key: "fatura",
                  label: "Fatura",
                },
                {
                  key: "whs",
                  label: "Wholesaler",
                },
                {
                  key: "mix",
                  label: "Mix",
                },
              ],
              placeholder: "Select Type",
              multiple: true,
            },
          },
          {
            key: "start_date",
            label: "TransactionPageStartDateFilterLabel",
            compName: "startDateMetric",
          },
          {
            key: "end_date",
            label: "TransactionPageEndDateFilterLabel",
            compName: "endDateMetric",
          },
        ],
      },
      showOffersDialog: false,
      clickedOffer: null,
      cancellationReasonsConfig: [],
      clickedOfferToDeactivate: null,
    };
  },
  methods: {
    //tab methods
    chosenTabHandler() {
      const currentSessionTabs = sessionStorage.getItem("sessionTabs");
      if (currentSessionTabs) {
        let parseData = JSON.parse(currentSessionTabs);
        if (parseData.offersTabs) {
          this.activeTabName = parseData.offersTabs;
          return;
        }
      } else {
        const defaultTabName = "upcoming";
        this.activeTabName = defaultTabName;
        this.storeActiveTab(defaultTabName);
      }
    },
    storeActiveTab(value) {
      let currentSessionObject = sessionStorage.getItem("sessionTabs");
      let finalValue;
      if (currentSessionObject) {
        let parseData = JSON.parse(currentSessionObject);
        finalValue = {
          ...parseData,
          offersTabs: value,
        };
      } else {
        finalValue = { offersTabs: value };
      }
      sessionStorage.setItem("sessionTabs", JSON.stringify(finalValue));
    },
    tabClicked(eventObject) {
      this.storeActiveTab(eventObject.name);
      this.reloadPage();
    },
    //data methods
    async getOffers(page = 1, chosenFilters) {
      const filters = { ...chosenFilters, tab: this.activeTabName };
      try {
        const response = await offersService.fetchOffers(page, filters);
        const responseData = response.data.data;
        this.originalData = JSON.parse(JSON.stringify(responseData.offers));
        this.offersList = responseData.offers.map((singleOffer) => {
          singleOffer = {
            ...singleOffer,
          };
          return singleOffer;
        });
        this.pagination = {
          count: responseData.total,
          next: responseData.next,
          pages: responseData.pages,
        };
        return response;
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    tempMethod(obj) {
      console.log("obj", obj);
    },
    //filters methods
    filtersChanged(key, value) {
      if (!(typeof value === "number") && _.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.offers = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.reloadPage();
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.offers = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsOffers", []);
      this.reloadPage();
    },
    saveMetircs() {
      localStorage.setItem("metricsOffers", JSON.stringify(this.metricsList));
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("metricsOffers", JSON.stringify(this.metricsList));
      this.metricsList = [...this.metricsList];
    },
    reloadPage() {
      if (this.$route.fullPath != "/marketing/offers?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getOffers(this.currentPage, { ...this.filters });
    },
    initialFiltersHandler() {
      let filtersSession = sessionStorage.getItem("filter");
      if (filtersSession) {
        filtersSession = JSON.parse(filtersSession);
        if (filtersSession.offers) {
          const filtersObject = filtersSession.offers;
          if (filtersObject && filtersObject.filters)
            this.filters = { ...filtersObject.filters };
        }
      }
      let metricsSession = localStorage.getItem("metricsOffers");
      if (metricsSession) {
        this.metricsList = JSON.parse(metricsSession);
      }
    },
    offerCreated() {
      this.showOffersDialog = false;
      this.getOffers(this.currentPage, this.filters);
    },
    offerEdited() {
      this.clickedOffer = null;
      this.getOffers(this.currentPage, this.filters);
    },
    offerRowClicked(clickedRow) {
      this.clickedOffer = JSON.parse(JSON.stringify(clickedRow));
    },
    async stopOffer(clickedRow) {
      let htmlOutput = `<div>${clickedRow.entity.name} - ${clickedRow.product.name} - ${clickedRow.unit.name} </div>`;
      this.$confirm(htmlOutput, "Stop this offer ?", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        cancelButtonText: this.$t("GlobalCancelBtn"),
        type: "Warning",
        dangerouslyUseHTMLString: true,
        lockScroll: false,
      })
        .then(async () => {
          try {
            const offerId = clickedRow.id;
            const apiObject = {
              stop_autoActivation: true,
              is_active: false,
              max_per_order: clickedRow.max_per_order,
              min_per_order: clickedRow.min_per_order,
              item_per_order: clickedRow.item_per_order,
              type: clickedRow.type,
              entity_amount: clickedRow.entity_amount,
              fatura_amount: clickedRow.fatura_amount,
              promoter: clickedRow.promoter,

              display_order: clickedRow.display_order,
              price: clickedRow.price,
              original_price: clickedRow.original_price,
              entity_id: clickedRow.entity_id,
              product_id: clickedRow.product_id,
              unit_id: clickedRow.unit_id,
              // start_date: this.$moment(clickedRow.start_date)
              //   .format("YYYY-MM-DD HH:MM:SS")
              //   .toString(),
              // end_date: this.$moment(clickedRow.end_date)
              //   .format("YYYY-MM-DD HH:MM:SS")
              //   .toString(),
              start_date: clickedRow.start_date,
              end_date: clickedRow.end_date,
            };
            if (clickedRow.communication_method) {
              apiObject.communication_date = clickedRow.communication_date;
              apiObject.communication_description =
                clickedRow.communication_description;
              apiObject.communication_title = clickedRow.communication_title;
              apiObject.communication_method = clickedRow.communication_method;
            }
            const response = await offersService.editOffer(offerId, apiObject);
            if (response && response.status == 200) {
              this.$globalFunctions.popupMessageWrapper(
                "Offer stopped successfully",
                "success",
                2000
              );
            }
          } catch (error) {
            console.log("error :>> ", error);
            const errorMessage =
              this.$globalFunctions.errorMessageExtractor(error);
            this.$globalFunctions.popupMessageWrapper(
              errorMessage,
              "error",
              4000
            );
          }
        })
        .catch(() => {});
    },
    async deactivateOffer(returnedObject) {
      try {
        const offerId = returnedObject.id;
        const apiObject = JSON.parse(JSON.stringify(returnedObject));
        const response = await offersService.editOffer(offerId, apiObject);
        if (response && response.status == 200) {
          this.$globalFunctions.popupMessageWrapper(
            "Offer deactivated successfully",
            "success",
            2000
          );
          this.clickedOfferToDeactivate = null;
          this.getOffers(this.currentPage, this.filters);
        }
      } catch (error) {
        console.log("error :>> ", error);
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 4000);
      }
    },
  },
  async created() {
    this.initialFiltersHandler();
    this.chosenTabHandler();
    this.getOffers(this.currentPage, this.filters);
    try {
      const response = await adminActions.getConfig("offers");
      this.cancellationReasonsConfig =
        response.data.config.Offers_cancellation_reasons;
    } catch (error) {
      console.log("error", error);
    }
  },
  watch: {
    $route(to) {
      this.getOffers(to.query.page, this.filters);
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    unitsLookup() {
      let unitsLookupArray =
        this.lookups && this.lookups.unitsMain ? this.lookups.unitsMain : [];
      let returnedArray = unitsLookupArray.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
      return returnedArray;
    },
    wholesalersLookup() {
      let whsLookupArray =
        this.lookups && this.lookups.distributers
          ? this.lookups.distributers
          : [];
      let returnedArray = whsLookupArray.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
      return returnedArray;
    },
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? parseInt(currentPageQuery) : 1;
    },
  },
};
</script>

<style lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
