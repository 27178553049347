<template>
  <el-dialog
    :title="`Deactivate this offer ?`"
    :visible.sync="localVisible"
    @close="closeDialogAction"
    width="30%"
  >
    <div v-if="value" class="u-bottom-margin--3x">
      {{ `${value.entity.name} - ${value.product.name} - ${value.unit.name}` }}
    </div>
    <el-radio-group v-model="radio">
      <template>
        <el-radio
          v-for="item in possibleReasons"
          :key="item"
          class="u-bottom-margin--2x"
          style="display: block"
          :label="item"
          >{{ item }}
        </el-radio>
      </template>
    </el-radio-group>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "OffersDeactivationDialog",
  props: {
    value: {
      type: Object,
      required: false,
    },
    possibleReasons: {
      default: () => [],
    },
  },
  data() {
    return {
      localVisible: false,
      radio: null,
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.localVisible = false;
      this.radio = null;
      this.$emit("input", null);
    },
    async saveClicked() {
      const chosenRadioValue = this.radio;
      if (!chosenRadioValue) {
        this.$globalFunctions.popupMessageWrapper(
          this.$t("TransactionDetailsPageStopAutoAssignmentPopupValidation1"),
          "error",
          3000
        );
        return;
      }
      let clickedRow = this.value;
      let apiObject = {
        is_active: false,
        max_per_order: clickedRow.max_per_order,
        min_per_order: clickedRow.min_per_order,
        item_per_order: clickedRow.item_per_order,
        type: clickedRow.type,
        entity_amount: clickedRow.entity_amount,
        fatura_amount: clickedRow.fatura_amount,
        promoter: clickedRow.promoter,

        display_order: clickedRow.display_order,
        price: clickedRow.price,
        original_price: clickedRow.original_price,
        entity_id: clickedRow.entity_id,
        product_id: clickedRow.product_id,
        unit_id: clickedRow.unit_id,
        // start_date: this.$moment(clickedRow.start_date)
        //   .format("YYYY-MM-DD HH:mm:ss")
        //   .toString(),
        // end_date: this.$moment(clickedRow.end_date)
        //   .format("YYYY-MM-DD HH:mm:ss")
        //   .toString(),
        start_date: clickedRow.start_date,
        end_date: clickedRow.end_date,
        deactivation_reason: chosenRadioValue,
        id: clickedRow.id,
      };
      if (clickedRow.communication_method) {
        apiObject.communication_date = clickedRow.communication_date;
        apiObject.communication_description =
          clickedRow.communication_description;
        apiObject.communication_title = clickedRow.communication_title;
        apiObject.communication_method = clickedRow.communication_method;
      }
      this.$emit("saveClicked", apiObject);
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.radio = null;
        this.localVisible = true;
      } else {
        this.localVisible = false;
      }
    },
    localVisible(value) {
      if (value === false) {
        this.$emit("input", null);
      }
    },
  },
};
</script>

<style></style>
